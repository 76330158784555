const keys = {

    PAGE_LENGTH: 10,
    ADMIN: 'Admin',

    INSTALLMENT_PARTIALLY_PAID: "Partially Paid",
    INSTALLMENT_PAID: "Paid",
    INSTALLMENT_DUE: "Due",
    INSTALLMENT_OVERDUE: "Overdue",

    INSTALLMENT: "Installment",
    EARLY_CLOSER: "Early Closer",

    EXPORT_CUSTOMER_CSV: "Export Customer CSV",
    LOGOUT : 'Logout',
    LOGIN : 'Login',
    ACTION_TYPE: 'log_type',
    SLOT_RANGE :['10AM - 12PM', '12PM - 2PM', '3PM - 5PM','5PM - 7PM']

}

export default keys