<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",

  // beforeDestroy() {
  //   this.request_POST(self, this.$urls.LOGOUT_TEAMMATE);
  // },
};
</script>

<style lang="scss">
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555555d5;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<style type="text/css" media="screen">
@import "./app-css/style.css";
</style>
