const headers = {
  lead_headers: [
    {
      text: "Lead ID",
      align: "start",
      sortable: false,
      value: "lead_number",
      class: "primary_2 white--text",
    },
    {
      text: "Customer Details",
      align: "start",
      sortable: false,
      value: "customer_details",
      class: "primary_2 white--text",
    },
    {
      text: "Signed-Up At",
      align: "start",
      sortable: false,
      value: "signed_up_at",
      class: "primary_2 white--text",
    },
    {
      text: "City, State",
      align: "start",
      sortable: false,
      value: "city",
      class: "primary_2 white--text",
    },
    {
      text: "Initiated On",
      align: "start",
      sortable: false,
      value: "initiated_on",
      class: "primary_2 white--text",
    },
    {
      text: "Actions",
      align: "start",
      sortable: false,
      value: "actions",
      class: "primary_2 white--text",
    },
  ],
  interal_ec_headers: [
    {
      text: "Lead ID",
      align: "start",
      sortable: false,
      value: "lead_number",
      class: "primary_2 white--text",
    },
    {
      text: "Customer Details",
      align: "start",
      sortable: false,
      value: "customer_details",
      class: "primary_2 white--text",
    },
    {
      text: "Signed-Up At",
      align: "start",
      sortable: false,
      value: "signed_up_at",
      class: "primary_2 white--text",
    },
    {
      text: "City, State",
      align: "start",
      sortable: false,
      value: "city",
      class: "primary_2 white--text",
    },
    {
      text: "NBFC Details",
      align: "start",
      sortable: false,
      value: "nbfc_details",
      class: "primary_2 white--text",
    },
    {
      text: "Initiated On",
      align: "start",
      sortable: false,
      value: "initiated_on",
      class: "primary_2 white--text",
    },
    {
      text: "Actions",
      align: "start",
      sortable: false,
      value: "actions",
      class: "primary_2 white--text",
    },
    // {
    //   text: "Pincode",
    //   align: "start",
    //   sortable: false,
    //   value: "pin_code",
    // class: "primary_2 white--text",
    // },
    // {
    //   text: "CIBIL",
    //   align: "start",
    //   sortable: false,
    //   value: "cibil_score",
    // class: "primary_2 white--text",
    // },
  ],
};

export default headers;
