export const namespaced = true;

export const state = {
  selected_tab: null,
  user_status: null,
  alloted_nbfc: "",
  loan_status: "",
  page_number: 1,
  date_range: [],
  display_daterange: "",
  applied_date_range: [],
  applied_display_daterange: "",
};

export const mutations = {
  SET_FILTER(
    state,
    {
      selected_tab,
      user_status,
      alloted_nbfc,
      loan_status,
      page_number,
      date_range,
      display_daterange,
      applied_date_range,
      applied_display_daterange,
    }
  ) {
    state.selected_tab = selected_tab;
    state.user_status = user_status;
    state.alloted_nbfc = alloted_nbfc;
    state.loan_status = loan_status;
    state.page_number = page_number;
    state.date_range = date_range;
    state.display_daterange = display_daterange;
    state.applied_date_range = applied_date_range;
    state.applied_display_daterange = applied_display_daterange;
  },
};

export const actions = {
  setFilters({ commit }, items) {
    commit("SET_FILTER", items);
  },
};
export const getters = {
  getFilters: (state) => {
    return state;
  },
};
