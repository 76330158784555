import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "./app-js/router.js";
import VueCryptojs from "vue-cryptojs";

import lang from "./app-js/lang.js";
import messages from "./app-js/messages.js";
import urls from "./app-js/urls.js";
import rules from "./app-js/rules.js";
import keys from "./app-js/keys.js";
import pre_verification_headers from "./app-js/headers/per-verification";
import pl_funnel_headers from "./app-js/headers/pl-funnel";

import axios_instance from "./app-js/helper-axios.js";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";

import { store } from "./store/";

Vue.prototype.$lang = lang;
Vue.prototype.$messages = messages;
Vue.prototype.$urls = urls;
Vue.prototype.$keys = keys;
Vue.prototype.$rules = rules;
Vue.prototype.$pre_verification_headers = pre_verification_headers;
Vue.prototype.$pl_funnel_headers = pl_funnel_headers;

Vue.config.productionTip = false;

Vue.use(VueCryptojs);
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

Vue.mixin({
  beforeRouteLeave: function(to, from, next) {
    // console.log("base before route leave");
    // console.log(to, "this is to");
    // console.log(to.name.toLowerCase());
    if (to.meta.requiresAuth == true) {
      let permission_list =
        JSON.parse(sessionStorage.getItem("permission_list")) || [];
      permission_list = permission_list.join(" ").toLowerCase();
      if (permission_list.includes(to.meta.title.toLowerCase())) next();
      else next(from);
    }
    next();
  },
  methods: {
    getPermission(item) {
      let permission_list =
        JSON.parse(sessionStorage.getItem("permission_list")) || [];
      if (permission_list.includes(item)) {
        return true;
      } else {
        return false;
      }
    },
    showSnakeBar(type, text) {
      this.$store.dispatch("snackbar/setSnackbar", {
        flag: true,
        text,
        color: type,
      });
    },
    toggle_loading() {
      this.$store.dispatch("setPageLoader", !this.$store.state.page_loader);
    },
    handle401(status_code) {
      // console.log(status_code);
      if (status_code === 401) {
        // console.log("Invalid Access token");
        this.showSnakeBar("error", "Invalid Access token");
      }
    },
    handle440(status_code) {
      if (status_code === 440) {
        console.log("Session Expired");
        this.showSnakeBar("error", "Session Expired");
      }
    },
    handle404(status_code) {
      if (status_code === 404) {
        console.log("Page Not Found");
        this.showSnakeBar("error", "Page Not Found");
      }
    },
    handle403(status_code) {
      if (status_code === 403) {
        console.log("Invalid token");
        this.showSnakeBar("error", "Invalid token");
      }
    },
    /* handel 500 error*/
    handle500(status_code) {
      console.log(status_code);
      if (status_code === 500) {
        console.log("Internal server error");
        this.showSnakeBar("error", "Internal server error");
      }
    },
    /* handel 500 error*/
    handleNoInternetConnection() {
      console.log("No Internet");
      this.showSnakeBar("error", "Please check your internet connection");
    },

    /* common  function  for GET API call */
    request_GET: (
      self,
      url,
      params,
      fnSuccessResponse = null,
      headers = null,
      fnFailureResponse = null,
      finallyHandler = null,
      isCancel = false
    ) => {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }

      if (headers == null) {
        headers = {
          token: sessionStorage.getItem("token"),
          "application-type": "web",
        };
      }

      axios_instance
        .get(url, {
          params: params,
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function(response) {
          // console.log("response")
          if (fnSuccessResponse != null) {
            if (response.data.success == false)
              self.showSnakeBar("error", response.data.message);

            fnSuccessResponse(response);
          }
        })
        .catch(function(error) {
          // console.log(error.response);

          if (error.response) {
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
          } else {
            if (fnFailureResponse != null) {
              fnFailureResponse();
            }
            // else {
            //     // self.handleNoInternetConnection()
            // }
          }
        })
        .finally(function(res) {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    /* common function for the POST API Call */
    request_POST(
      self,
      url,
      params,
      fnSuccessResponse = null,
      headers = null,
      fnFailureResponse = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let token = "";
        if (sessionStorage.getItem("token"))
          token = sessionStorage.getItem("token");

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .post(url, params, {
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function(response) {
          // console.log("response.data.success=" + response.data.success);
          if (fnSuccessResponse != null) {
            if (response.data.success == false)
              self.showSnakeBar("error", response.data.message);

            fnSuccessResponse(response);
          }
        })
        .catch(function(error) {
          console.log(error);
          if (!error.response) {
            console.log(error);
          } else {
            console.log(error.response.data.status_code);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            if (fnFailureResponse != null) {
              fnFailureResponse();
            }
            // else {
            //     self.handleNoInternetConnection()
            // }
          }
        })
        .finally(function(res) {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    /* common function for the PATCH API Call */
    request_PATCH(
      self,
      url,
      params,
      body,
      fnSuccessResponse = null,
      headers = null,
      fnFailureResponse = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let token = "";
        if (sessionStorage.getItem("token"))
          token = sessionStorage.getItem("token");

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .patch(url + params, body, {
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function(response) {
          // console.log("response.data.success=" + response.data.success);
          if (fnSuccessResponse != null) {
            if (response.data.success == false)
              self.showSnakeBar("error", response.data.message);

            fnSuccessResponse(response);
          }
        })
        .catch(function(error) {
          console.log(error);
          if (!error.response) {
            console.log(error);
          } else {
            console.log(error.response.data.status_code);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            if (fnFailureResponse != null) {
              fnFailureResponse();
            }
            // else {
            //     self.handleNoInternetConnection()
            // }
          }
        })
        .finally(function(res) {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    request_PUT(
      self,
      url,
      look_up_key,
      body,
      fnSuccessResponse = null,
      headers = null,
      fnFailureResponse = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let token = "";
        if (sessionStorage.getItem("token"))
          token = sessionStorage.getItem("token");

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .put(url + look_up_key, body, {
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function(response) {
          // console.log("response.data.success=" + response.data.success);
          if (fnSuccessResponse != null) {
            if (response.data.success == false)
              self.showSnakeBar("error", response.data.message);

            fnSuccessResponse(response);
          }
        })
        .catch(function(error) {
          console.log(error);
          if (!error.response) {
            console.log(error);
          } else {
            console.log(error.response.data.status_code);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            if (fnFailureResponse != null) {
              fnFailureResponse();
            }
            // else {
            //     self.handleNoInternetConnection()
            // }
          }
        })
        .finally(function(res) {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    /*perform encription for Rout params */
    encript: function(text) {
      return this.CryptoJS.AES.encrypt(text.toString(), "cashpo").toString();
    },
    /*perform decription for Rout params*/
    decrypt: function(text) {
      return this.CryptoJS.AES.decrypt(text, "cashpo").toString(
        this.CryptoJS.enc.Utf8
      );
    },

    /*convert date formte to dd-mm-yyyy*/
    display_date_formate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      let new_date = `${day}-${month}-${year}`;
      return new_date;
    },
    display_date_formate2(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      let new_date = `${year}-${month}-${day}`;
      return new_date;
    },

    // new axios methods

    GET_request(
      self,
      url,
      look_up_key,
      params,
      successHandler = null,
      headers = null,
      failureHandler = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }

      if (headers == null) {
        headers = {
          token: sessionStorage.getItem("token"),
          "application-type": "web",
        };
      }

      axios_instance
        .get(url + look_up_key, {
          params: params,
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function(response) {
          if (successHandler != null) {
            if (response.status != 200 && response.status != 201) {
              self.showSnakeBar("error", response.data.detail);
              console.log("GET then error: ", response);
            }
            successHandler(response);
          }
        })
        .catch(function(error) {
          if (!error.response) {
            if (error.message) {
              console.log("GET catch !error.response", error);
              self.showSnakeBar("error", error);
            }
          } else {
            self.showSnakeBar("error", error.response.data.detail);
            // console.log("Post catch error:", error.response.data);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            console.log("GET catch error.response:", error);
            if (failureHandler != null) {
              failureHandler();
            }
          }
        })
        .finally(function(res) {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    POST_request(
      self,
      url,
      params,
      fnSuccessResponse = null,
      headers = null,
      failureHandler = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let token = "";
        if (sessionStorage.getItem("token"))
          token = sessionStorage.getItem("token");

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .post(url, params, {
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function(response) {
          if (fnSuccessResponse != null) {
            if (response.status != 200 && response.status != 201) {
              self.showSnakeBar("error", response.data.detail);
            }
            fnSuccessResponse(response);
            self.showSnakeBar("success", response.data.detail);
          }
        })
        .catch(function(error) {
          if (!error.response) {
            console.log("POST catch !error.response", error);
            self.showSnakeBar("error", error);
          } else {
            self.showSnakeBar("error", error.response.data.detail);
            // console.log("Post catch error:", error.response.data);
            console.log("POST catch error.response:", error);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle404(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            if (failureHandler != null) {
              failureHandler();
            }
          }
        })
        .finally(function(res) {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    PATCH_request(
      self,
      url,
      look_up_key,
      params,
      body,
      fnSuccessResponse = null,
      headers = null,
      failureHandler = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let token = "";
        if (sessionStorage.getItem("token"))
          token = sessionStorage.getItem("token");

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .patch(url + (look_up_key ? `${look_up_key}/` : ""), body, {
          headers: headers,
          params: params,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function(response) {
          if (fnSuccessResponse != null) {
            if (response.status != 200 && response.status != 201) {
              self.showSnakeBar("error", response.data.detail);
            }
            fnSuccessResponse(response);
            self.showSnakeBar("success", response.data.detail);
          }
        })
        .catch(function(error) {
          if (!error.response) {
            console.log("PATCH catch !error.response", error);
            self.showSnakeBar("error", error);
          } else {
            self.showSnakeBar("error", error.response.data.detail);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            console.log("PATCH catch error.response", error);
            if (failureHandler != null) {
              failureHandler();
            }
          }
        })
        .finally(function(res) {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    DELETE_request(
      self,
      url,
      look_up_key,
      params,
      fnSuccessResponse = null,
      headers = null,
      failureHandler = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let token = "";
        if (sessionStorage.getItem("token"))
          token = sessionStorage.getItem("token");

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .delete(url + (look_up_key ? `${look_up_key}/` : ""), {
          headers: headers,
          params: params,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function(response) {
          if (fnSuccessResponse != null) {
            if (response.status != 200 && response.status != 201) {
              self.showSnakeBar("error", response.data.detail);
              console.log("DELETE error", response.data.detail);
            }
            fnSuccessResponse(response);
            self.showSnakeBar("success", response.data.detail);
          }
        })
        .catch(function(error) {
          if (!error.response) {
            console.log("DELETE catch !error.response", error);
            self.showSnakeBar("error", error);
          } else {
            self.showSnakeBar("error", error.response.data.detail);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            console.log("DELETE catch error.response", error);
            if (failureHandler != null) {
              failureHandler();
            }
          }
        })
        .finally(function(res) {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
  },
});

const eventsHub = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
