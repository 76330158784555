const lang = {
  /* all string  for languages */

  SEARCH: "Search",
  ADD: "Add",
  SAVE: "Save",
  DELETE: "Delete",
  CANCEL: "Cancel",
  ARE_YOU_SURE: "Are you sure",
  WANT_TO_DELETE: "want to delete",

  MOBILE: "Mobile",
  OTP: "OTP",
  LOGIN: "Login",
  LOGOUT: "Logout",
  CONTINUE: "Continue",
  RESEND_OTP: "Resend OTP?",
  BASIC_INFO: "Basic Information",
  CUSTOMER_ID: "Customer ID",
  NAME: "Name",
  EMAIL: "Email",
  GENDER: "Gender",
  DATE_OF_BIRTH: "DOB",
  FATHER_NAME: "father's name",
  MOTHER_NAME: "Mother's name",
  MARITAL_STATUS: "Marital status",
  EDUCATION: "Education",
  INSTITUTE_NAME: "Institute name",
  PAN: "PAN",
  AADHAAR: "Aadhaar",
  PERMISSIONS: "Permissions",

  ADDRESS: "Address",
  ADDRESS_DETAILS: "Address Details",
  CURRENT_ADDRESS: "Current Address",
  PERMANENT_ADDRESS: "Permanent Address",

  RESIDENTIAL_TYPE: "Residential type",
  HOME_NUMBER: "Home number",
  AREA: "Area",
  PIN_CODE: "Pin code",
  CITY: "City",
  STATE: "State",
  DURATION_OF_ADDRESS: "Duration of address",
  SAME_AS_CURRENT_ADDRESS: "Same as current address",

  REFERENCES: "References",
  REFERENCE_TYPE: "Reference type",
  CONTACT_NAME: "Contact name",
  RELATION: "Relation",

  EMPLOYMENT_DETAILS: "Employment Details",
  AADHAAR_DETAILS: "Aadhaar Details",

  EMPLOYMENT_TYPE: "Employment type",
  EMPLOYER_NAME: "Employer name",
  EMPLOYER_ADDRESS: "Employer address",
  EMPLOYER_PIN_CODE: "Employer pin code",
  EMPLOYER_STATE: "Employer state",
  EMPLOYER_CITY: "Employer city",
  DESIGNATION: "Designation",
  MONTHLY_SALARY: "Monthly salary",
  SALARY_TYPE: "Salary type",
  EXPERIENCE: "Experience",
  EXPERIENCE_IN_YEAR: "Experience in year",
  EXPERIENCE_IN_MONTH: "Experience in month",
  OFFICIAL_EMAIL: "Official email",

  BANK_DETAILS: "Bank Details",
  ACCOUNT_HOLDER_NAME: "Account holder name",
  ACCOUNT_NUMBER: "Account number",
  IFSC_CODE: "IFSC scode",

  DOCUMENTS: "Documents",
  LOAN_HISTORY: "Loan History",

  ADD_MEMBER: "Add Member",
  EDIT_MEMBER: "Edit Member",
  CHARGE: "Charge",
  INTERNAL_COMMENTS: "Internal Comments",
  LOAN_ID: "Loan ID",
  EMI_ID: "EMI ID",
  TOTAL_PAYABLE: "Total Payable",
  PAYMENT_DATE: "Payment Date ",
  PAYMENT_ID: "Payment ID ",
  ENTER_AMOUNT_RECEIVED: "Enter Amount Received",
  PAYMENT_MODE: "Payment Mode",
  REMARK: "Remark",
  RECEIVED: "Received",
  MANUAL_PAYMENT_ENTRY: "Manual Payment Entry",
  ADD_COMMENT: "Add Comment",
  COMMENT: "Comment",
  EXPORT_CSV: "Export CSV",
  ENABLE: "Enable",
  DISABLE: "Disable",
  FOLLOW_UP_DATE: "Follow up Date",

  SESSION_TIMEOUT: "Session Timeout",

  VIDEO_VERIFICATION: "Video verification Details",
  TIME_SLOT: "Date & Time",
  VERIFIED: "Verified",
  EDIT_SLOT: "Video Verification Slot",
  SLOT: "Slot Time Range",
  SLOT_DATE: "Slot Date",
  UPLOAD_AADHAAR_CARD: "Upload Aadhaar ",
  UPLOAD_PAN_CARD: "Upload PAN ",
  UPLOAD_SELIFE_CARD: "Upload Selfie ",
  PAN_NO: "Pan No.",
  AADHAAR_NO: "Aadhar No.",
  FULL_NAME: "Full Name",
  USER_AGREEMENT: "User Agreement",
  PAN_ATTEMPT: "Pan Attempt",
  CONFIRM_AMOUNT: "Confirm Amount",
  MANUAL_PAYMENT: 'Manual Payment',

  EMI_DETAILS: "EMI Details",
  CUSTOMER_PAYMENT_BREAKDOWN: "Customer Payment Breakdown",
};

export default lang;
