export const namespaced = true;

export const state = {
  nbfc_name: "",
  selected_tab: 0,
  page_number: 1,
};

export const mutations = {
  SET_FILTER(state, { nbfc_name, selected_tab, page_number }) {
    state.nbfc_name = nbfc_name;
    state.selected_tab = selected_tab;
    state.page_number = page_number;
  },
};

export const actions = {
  setFilters({ commit }, items) {
    commit("SET_FILTER", items);
  },
};
export const getters = {
  getFilters: (state) => {
    return state;
  },
};
