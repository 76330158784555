export const namespaced = true

export const state = {
    loan_status: null,
    loan_steps: null,
    date_range: [],
    display_daterange: "",
    current_city: "",
    page_number: 1,
    fd_display_date: null,
    follow_up_date: null,
    label: null,
    source: null,
    // for status date filter
    status_date_range: [],
    display_status_daterange: "",
    
    profile_status: "",
    profile_step: "",
    selected_tab: 0,

    loan_id: null,
    kyc_step: 0,
    kyc_status: 0,
    platform: "",
}

export const mutations = {
    SET_FILTER(state, {loan_status, profile_status, loan_steps, date_range, display_daterange, display_status_daterange, status_date_range, page_number, profile_step, selected_tab, kyc_status, kyc_step, platform}) {
        state.loan_status = loan_status;
        state.profile_status = profile_status;
        state.page_number = page_number;
        state.loan_steps = loan_steps;
        state.date_range = date_range;
        state.display_daterange = display_daterange;
        state.profile_step = profile_step;
        state.selected_tab = selected_tab;
        state.kyc_status = kyc_status;
        state.kyc_step = kyc_step;

        // for status date filter
        state.status_date_range = status_date_range;
        state.display_status_daterange = display_status_daterange;
        state.platform = platform;
    },
    SET_LOAN_ID(state, loan_id) {
        state.loan_id = loan_id;
    },

}

export const actions = {
    setFilters({commit}, items) {
        commit('SET_FILTER', items)
    },
    setLoanId({commit}, items) {
        commit('SET_LOAN_ID', items)
    },
}
export const getters = {
    getFilters: state => {
        return state;
    },
    getLoanId: state => {
        return state.loan_id;
    },
}