import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: { requiresAuth: false, title: "Login", show_back_btn: false },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/login-page/Login.vue"),
  },
  // {
  //     path: '/',
  //     name: 'base',npm
  //     component: () => import( /* webpackChunkName: "WarehouseProfile" */ "@/components/layout/Base.vue"),
  // },
  {
    path: "/",
    name: "admin",
    component: () =>
      import(
        /* webpackChunkName: "WarehouseProfile" */ "@/components/layout/Base.vue"
      ),
    children: [
      {
        path: "customer",
        name: "customers",
        meta: { requiresAuth: true, title: "Customer", show_back_btn: false },
        component: () =>
          import(/* webpackChunkName: "customer" */ "@/pages/customer-pages/"),
      },
      {
        path: "customer/details/:id",
        name: "customer-details",
        meta: {
          requiresAuth: true,
          title: "Customer details",
          show_back_btn: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/pages/new-customer-pages/NewCustomerDetails"
          ),
        // component: () => import( /* webpackChunkName: "customer" */ "@/pages/customer-pages/CustomerDetails")
      },
      {
        path: "team/",
        name: "team",
        meta: { requiresAuth: true, title: "Team", show_back_btn: false },
        component: () =>
          import(/* webpackChunkName: "team" */ "@/pages/team-pages/"),
      },
      {
        path: "emi-analysis/",
        name: "emi-analysis",
        meta: {
          requiresAuth: true,
          title: "EMI Analysis",
          show_back_btn: false,
        },
        component: () =>
          import(/* webpackChunkName: "team" */ "@/pages/emi-analysis/"),
      },
      {
        path: "payment/",
        name: "payment",
        meta: { requiresAuth: true, title: "Payment", show_back_btn: false },
        component: () =>
          import(/* webpackChunkName: "team" */ "@/pages/payments/"),
      },
      {
        path: "review/",
        name: "review",
        meta: { requiresAuth: true, title: "Review", show_back_btn: false },
        component: () =>
          import(/* webpackChunkName: "team" */ "@/pages/review/index"),
      },
      {
        path: "service_area/",
        name: "service_area",
        meta: {
          requiresAuth: true,
          title: "Service Area",
          show_back_btn: false,
        },
        component: () =>
          import(/* webpackChunkName: "team" */ "@/pages/service-area/index"),
      },
      {
        path: "session_history/",
        name: "session_history",
        meta: {
          requiresAuth: true,
          title: "Session History",
          show_back_btn: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "session" */ "@/pages/session-pages/index"
          ),
      },
      {
        path: "session/detail/:id",
        name: "session_details",
        meta: {
          requiresAuth: true,
          title: "Session Detail",
          show_back_btn: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "session detail" */ "@/pages/session-pages/session-detail"
          ),
      },
      // new tabs for new onboarding customer
      {
        path: "new-customer",
        name: "new-customers",
        meta: {
          requiresAuth: true,
          title: "New Customer",
          show_back_btn: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "new customer" */ "@/pages/new-customer-pages/"
          ),
      },
      {
        path: "new-customer/details/:id",
        name: "new-customer-details",
        meta: {
          requiresAuth: true,
          title: "Customer details",
          show_back_btn: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "new customer" */ "@/pages/new-customer-pages/NewCustomerDetails"
          ),
      },
      {
        path: "pre-verification",
        name: "pre-verification",
        meta: {
          requiresAuth: true,
          title: "Pre Verification",
          show_back_btn: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "new customer" */ "@/pages/pre-verification/"
          ),
      },
      {
        path: "pre-verification/details/:id",
        name: "pre-verification-details",
        meta: {
          requiresAuth: true,
          title: "Customer details",
          show_back_btn: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/pages/new-customer-pages/NewCustomerDetails"
          ),
        // component: () => import( /* webpackChunkName: "customer" */ "@/pages/customer-pages/CustomerDetails")
      },
      {
        path: "loan-applied",
        name: "loan-applied",
        meta: {
          requiresAuth: true,
          title: "Loan Applied",
          show_back_btn: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "new customer" */ "@/pages/loan-applied/"
          ),
      },
      {
        path: "loan-applied/details/:id",
        name: "loan-applied-details",
        meta: {
          requiresAuth: true,
          title: "Customer details",
          show_back_btn: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/pages/new-customer-pages/NewCustomerDetails"
          ),
        // component: () => import( /* webpackChunkName: "customer" */ "@/pages/customer-pages/CustomerDetails")
      },
      {
        path: "business-loan",
        name: "business-loan",
        meta: {
          requiresAuth: true,
          title: "Business Loan",
          show_back_btn: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "new customer" */ "@/pages/business-loan/"
          ),
      },
      {
        path: "business-loan/details/:id",
        name: "business-loan-details",
        meta: {
          requiresAuth: true,
          title: "Business Loan details",
          show_back_btn: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/pages/business-loan/BusinessLoanDetails"
          ),
        // component: () => import( /* webpackChunkName: "customer" */ "@/pages/customer-pages/CustomerDetails")
      },
      {
        path: "loan-against-securities",
        name: "loan-against-securities",
        meta: {
          requiresAuth: true,
          title: "LAS",
          show_back_btn: false,
        },
        component: () =>
          import(/* webpackChunkName: "new customer" */ "@/pages/las"),
      },
      {
        path: "notification-panel",
        name: "notification-panel",
        meta: {
          requiresAuth: true,
          title: "Notification Panel",
          show_back_btn: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "new customer" */ "@/pages/notifications"
          ),
      },
      {
        path: "pl-funnel",
        name: "pl-funnel",
        meta: {
          requiresAuth: true,
          title: "PL Funnel",
          show_back_btn: false,
        },
        component: () =>
          import(/* webpackChunkName: "new customer" */ "@/pages/pl-funnel"),
      },
    ],
  },
  {
    path: "/view-image/:document?/:image?",
    name: "view-image",
    meta: { requiresAuth: true, title: "Customer", show_back_btn: false },
    component: () =>
      import(
        /* webpackChunkName: "view image" */ "@/components/common/ViewImage"
      ),
  },
  {
    path: "/complete-cibil-report/:id",
    name: "compelete-cibil-report",
    meta: { requiresAuth: true, title: "CIBIL Report", show_back_btn: false },
    component: () =>
      import(
        /* webpackChunkName: "CIBIL Report" */ "@/components/new-customer/CompleteCibilDetails"
      ),
  },
  {
    path: "/complete-finvu-details/:finvu_doc_id",
    name: "compelete-finvu-details",
    meta: {
      requiresAuth: true,
      title: "Finvu Complete Details",
      show_back_btn: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "CIBIL Report" */ "@/components/new-customer/CompleteFinvuDetails"
      ),
  },
  // {
  //     path:'/logoutuser',
  //     name:'delete-account',
  //     meta: {requiresAuth: false, title: "Delete Account", show_back_btn: false},
  //     component:()=>import (/* webpackChunkName: "view image" */"@/pages/temporary-pages/DeleteAccount.vue")
  // },
  // {
  //     path:'/signRequest',
  //     name:'upload-signature',
  //     meta: {requiresAuth: false, title: "Upload Signature", show_back_btn: false},
  //     component:()=>import (/* webpackChunkName: "view image" */"@/pages/temporary-pages/UploadSignature.vue")
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/* redirecting to home page if access token is not provided*/
router.beforeEach((to, from, next) => {
  let access_token = sessionStorage.getItem("token");
  if (to.meta.requiresAuth == true && !access_token) {
    next({
      name: "login",
    });
  } else if (to.meta.requiresAuth == false && access_token) {
    next({
      name: "new-customers",
    });
  } else next();
});

export default router;
