const headers = {
  headers: [
    {
      text: "Lead No.",
      align: "start",
      sortable: false,
      value: "customer_id",
      class: "primary_2 white--text",
    },
    {
      text: "Customer Info",
      value: "name",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Father's Name",
      value: "father_name",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Signed-up At",
      value: "created",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "City",
      value: "city",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Initiated On",
      value: "initiated_on",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Applied Amount",
      value: "applied_amount",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Agent",
      value: "agent_assigned",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "NBFC",
      value: "alloted_nbfc",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Follow-up Date",
      value: "follow_up_date",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Comment",
      value: "comment",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      width: 120,
      class: "primary_2 white--text",
    },
  ],
  reject_headers: [
    {
      text: "Lead No.",
      align: "start",
      sortable: false,
      value: "customer_id",
      class: "primary_2 white--text",
    },
    {
      text: "Customer Info",
      value: "name",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Father's Name",
      value: "father_name",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Signed-up At",
      value: "created",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "City",
      value: "city",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Initiated On",
      value: "initiated_on",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Applied Amount",
      value: "applied_amount",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Agent",
      value: "agent_assigned",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "NBFC",
      value: "alloted_nbfc",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Rejection Reason",
      value: "rejection_reason",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Follow-up Date",
      value: "follow_up_date",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Comment",
      value: "comment",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      width: 120,
      class: "primary_2 white--text",
    },
  ],
  pending_by_nbfc: [
    {
      text: "Lead No.",
      align: "start",
      sortable: false,
      value: "customer_id",
      class: "primary_2 white--text",
    },
    {
      text: "Customer Info",
      value: "name",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Father's Name",
      value: "father_name",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Signed-up At",
      value: "created",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "City",
      value: "city",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Initiated On",
      value: "initiated_on",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Applied Amount",
      value: "applied_amount",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Tenure",
      value: "tenure",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Agent",
      value: "agent_assigned",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "NBFC",
      value: "alloted_nbfc",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Follow-up Date",
      value: "follow_up_date",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Comment",
      value: "comment",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      width: 120,
      class: "primary_2 white--text",
    },
  ],
  disbursed_completed_by_nbfc: [
    {
      text: "Lead No.",
      align: "start",
      sortable: false,
      value: "customer_id",
      class: "primary_2 white--text",
    },
    {
      text: "Customer Info",
      value: "name",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Father's Name",
      value: "father_name",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Signed-up At",
      value: "created",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "City",
      value: "city",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Initiated On",
      value: "initiated_on",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Applied Amount",
      value: "applied_amount",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Tenure",
      value: "tenure",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Disbursed Amount",
      value: "disbursed_amount",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Monthly EMI",
      value: "monthly_emi",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Agent",
      value: "agent_assigned",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "NBFC",
      value: "alloted_nbfc",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Follow-up Date",
      value: "follow_up_date",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Comment",
      value: "comment",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      width: 120,
      class: "primary_2 white--text",
    },
  ],
  approved_by_nbfc: [
    {
      text: "Lead No.",
      align: "start",
      sortable: false,
      value: "customer_id",
      class: "primary_2 white--text",
    },
    {
      text: "Customer Info",
      value: "name",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Father's Name",
      value: "father_name",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Signed-up At",
      value: "created",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "City",
      value: "city",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Initiated On",
      value: "initiated_on",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Applied Amount",
      value: "applied_amount",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Offer Details",
      value: "offer_details",
      sortable: false,
      class: "primary_2 white--text",
      width: 150,
    },
    {
      text: "Status",
      value: "nbfc_stages",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Agent",
      value: "agent_assigned",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "NBFC",
      value: "alloted_nbfc",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Follow-up Date",
      value: "follow_up_date",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Comment",
      value: "comment",
      sortable: false,
      class: "primary_2 white--text",
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      width: 120,
      class: "primary_2 white--text",
    },
  ],
};

export default headers;
