export const namespaced = true;

export const state = {
  customer_id: null,
  active_loan_id: null,

  precheck_status: "All",
  profile_status: null,
  loan_steps: null,
  date_range: [],
  display_daterange: "",
  current_city: "",
  page_number: 1,
  fd_display_date: null,
  follow_up_date: null,
  label: null,
  source: null,
  // for status date filter
  status_date_range: [],
  display_status_daterange: "",

  is_rent_address_proof_verified: false,
  is_bank_statement__verified: false,
  is_rent_address_proof_uploaded: false,
  is_bank_statement__uploaded: false,
  assigned_agent: "",
  agent_type: "",
  document_type: "",
  document_request_status: "",

  loan_id: null,

  basic_details_accordion: {
    title: "Basic Details",
    show: true,
  },
  personal_details_accordion: {
    title: "Personal Details",
    show: true,
  },
  documents_accordion: {
    title: "Documents",
    show: true,
  },
  business_details_accordion: {
    title: "Business Details",
    show: true,
  },
};

export const mutations = {
  SET_CUSTOMER_ID(state, value) {
    state.customer_id = value;
  },
  SET_FILTER(
    state,
    {
      precheck_status,
      profile_status,
      loan_steps,
      fd_display_date,
      date_range,
      display_daterange,
      display_status_daterange,
      status_date_range,
      page_number,
      assigned_agent,
      agent_type,
      document_type,
      current_city,
      document_request_status,
    }
  ) {
    state.precheck_status = precheck_status;
    state.profile_status = profile_status;
    state.page_number = page_number;
    state.loan_steps = loan_steps;
    state.date_range = date_range;
    state.current_city = current_city;
    state.display_daterange = display_daterange;
    state.fd_display_date = fd_display_date;

    // for status date filter
    state.status_date_range = status_date_range;
    state.display_status_daterange = display_status_daterange;

    state.assigned_agent = assigned_agent;
    state.document_request_status = document_request_status;
    state.document_type = document_type;
    state.agent_type = agent_type;
  },
  SET_LOAN_ID(state, loan_id) {
    state.loan_id = loan_id;
  },

  TOGGLE_BASIC_LOAN_ACCORDION(state) {
    state.basic_details_accordion.show = !state.basic_details_accordion.show;
  },
  TOGGLE_PERSONAL_LOAN_ACCORDION(state) {
    state.personal_details_accordion.show = !state.personal_details_accordion
      .show;
  },
  TOGGLE_DOCUMENTS_LOAN_ACCORDION(state) {
    state.documents_accordion.show = !state.documents_accordion.show;
  },
  TOGGLE_BUSINESS_LOAN_ACCORDION(state) {
    state.business_details_accordion.show = !state.business_details_accordion
      .show;
  },
};

export const actions = {
  setCustomerId({ commit }, value) {
    commit("SET_CUSTOMER_ID", value);
  },
  setFilters({ commit }, items) {
    commit("SET_FILTER", items);
  },
  setLoanId({ commit }, items) {
    commit("SET_LOAN_ID", items);
  },
  setBasicDetailsAccordion({ commit }) {
    commit("TOGGLE_BASIC_LOAN_ACCORDION");
  },
  setPersonalDetailsAccordion({ commit }) {
    commit("TOGGLE_PERSONAL_LOAN_ACCORDION");
  },
  setDocumentsAccordion({ commit }) {
    commit("TOGGLE_DOCUMENTS_LOAN_ACCORDION");
  },
  setBusinessDetailsAccordion({ commit }) {
    commit("TOGGLE_BUSINESS_LOAN_ACCORDION");
  },
};

export const getters = {
  getCustomerId: (state) => {
    return state.customer_id;
  },
  getFilters: (state) => {
    return state;
  },
  getLoanId: (state) => {
    return state.loan_id;
  },
  getBasicDetailsAccordion: (state) => {
    return state.basic_details_accordion;
  },
  getPersonalDetailsAccordion: (state) => {
    return state.personal_details_accordion;
  },
  getDocumentsAccordion: (state) => {
    return state.documents_accordion;
  },
  getBusinessDetailsAccordion: (state) => {
    return state.business_details_accordion;
  },
};
